
<template>
  <div class="container flex-wrap" style="width:100%;scroll-behavior:smooth;overflow:hidden;" ref="container">
    <!-- <div class="flex-wrap flex-center align-start" style="position:fixed;top:0;left:0;right:0;z-index:999999;scrollbar-width:none;-moz-user-select: none; -khtml-user-select: none; user-select: none;" ref="trigger">
      
    <NavBar v-model="index" @on-change="handleChange" style="z-index:100;width:20%;min-width:500px;"></NavBar>
    </div> -->
    <template v-for="(p,i) in pages">
      <div :key="p" class="page" >
        <component :is="p" :show="index == i" />
      </div>
    </template>
  </div>
</template>

<script>
  import operation from './cm/operation'
  import NavBar from '../components/panels/Nav'
 

  export default {
    components:{operation},
    data(){
      return {
        index:0,
        pages:['operation']
      }
    },
    computed:{
      width(){
        return this.$refs.container.clientWidth
      },
      current(){
        return this.pages[this.index]
      },
      left(){
        if(this.index == 0)
          return null
        else
          return this.pages[this.index-1]
      },
      right(){
        if(this.index == this.pages.length)
          return null
        else
          return this.pages[this.index+1]
      },
      uid(){
        return this.$route.query.uid
      }
    },
    mounted(){
      if(this.$route.query.key != 'nbgz'){
        this.RouteTo('/403')
      }
      
      this.$store.dispatch('board/getData')
    },
    methods:{
      
      handleChange(i){
        this.old = this.index
        this.index = i
        this.$refs.container.scrollLeft = i * this.width
      },
      startDrag(e){
        this.dragging = true
        this.oldX = e.offsetX
        console.log('down:',this.oldX)

      },
      onDrag(e){
        console.log('move')
        if(this.dragging && !this.timer){
          // this.timer =setTimeout(()=>{
          //   delete this.timer
          // },50)
          let offset = e.offsetX - this.oldX
          if(Math.abs(offset) > 2){
            this.oldX = e.offsetX
            
            this.$refs.container.scrollLeft = this.$refs.container.scrollLeft - offset
          }
        }
      },
      endDrag(e){
        this.dragging = false
        if(this.timer){
          clearTimeout(this.timer)
          delete this.timer
        }
        if(this.newX > this.oldX + 400){
          this.index--
          
        }else if(this.newX < this.oldX - 400){
          this.index++
        }

        this.$refs.container.scrollLeft = this.index * this.width
        console.log('end')
      }
    }
  }
</script>

<style lang="less">
.page{
  width:100%;
  height:100%;
  flex-shrink:0;
}
</style>